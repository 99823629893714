import React, { useState } from "react";
import {
  Text,
  Image,
  StyleSheet,
  View,
  Platform,
  TouchableOpacity,
  Alert,
} from "react-native";
import * as ImagePicker from "expo-image-picker";
import * as MediaLibrary from "expo-media-library";
import { Dimensions } from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Fontisto,
} from "@expo/vector-icons";
import { manipulateAsync, FlipType, SaveFormat } from "expo-image-manipulator";

export default function Editor() {
  const windowWidth = Dimensions.get("window").width;

  const [image, setImage] = useState(null);

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      quality: 1,
    });

    //console.log(result);

    if (!result.cancelled) {
      setImage(result.uri);
    }
  };

  const takePicture = async () => {
    await MediaLibrary.requestPermissionsAsync();
    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      quality: 1,
    });

    //console.log(result);

    if (!result.cancelled) {
      setImage(result.uri);
    }
  };

  const SaveToPhone = async (item) => {
    const permission = await MediaLibrary.requestPermissionsAsync();
    if (permission.granted) {
      try {
        const asset = await MediaLibrary.createAssetAsync(item);
        MediaLibrary.createAlbumAsync("Images", asset, false)
          .then(() => {
            console.log("File Saved Successfully!");
            createTwoButtonAlert();
          })
          .catch(() => {
            console.log("Error In Saving File!");
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Need Storage permission to save file");
    }
  };

  const createTwoButtonAlert = () =>
    Alert.alert("Image saved!", "", [
      { text: "OK", onPress: () => console.log("OK Pressed") },
    ]);

  const rotate90R = async () => {
    const manipResult = await manipulateAsync(image, [{ rotate: 90 }], {
      compress: 1,
      format: SaveFormat.PNG,
    });
    setImage(manipResult.uri);
  };

  const rotate90L = async () => {
    const manipResult = await manipulateAsync(image, [{ rotate: -90 }], {
      compress: 1,
      format: SaveFormat.PNG,
    });
    setImage(manipResult.uri);
  };

  const flipHorizontal = async () => {
    const manipResult = await manipulateAsync(
      image,
      [{ flip: FlipType.Horizontal }],
      {
        compress: 1,
        format: SaveFormat.PNG,
      }
    );
    setImage(manipResult.uri);
  };

  const flipVertical = async () => {
    const manipResult = await manipulateAsync(
      image,
      [{ flip: FlipType.Vertical }],
      {
        compress: 1,
        format: SaveFormat.PNG,
      }
    );
    setImage(manipResult.uri);
  };

  return (
    <View style={styles.container}>
      {windowWidth < 480 && (
        <View style={styles.imageContainer}>
          {image ? (
            <Image source={{ uri: image }} style={styles.image} />
          ) : (
            <TouchableOpacity onPress={pickImage} activeOpacity={0.6}>
              <Text>
                <MaterialCommunityIcons
                  name="image-edit-outline"
                  size={300}
                  color="white"
                />
              </Text>
            </TouchableOpacity>
          )}
        </View>
      )}

      {windowWidth > 480 && (
        <View style={styles.imageContainer}>
          {image ? (
            <Image source={{ uri: image }} style={styles.imageWeb} />
          ) : (
            <TouchableOpacity onPress={pickImage} activeOpacity={0.6}>
              <Text>
                <MaterialCommunityIcons
                  name="image-edit-outline"
                  size={300}
                  color="white"
                />
              </Text>
            </TouchableOpacity>
          )}
        </View>
      )}

      <View style={styles.buttons}>
        {Platform.OS != "web" && (
          <TouchableOpacity onPress={takePicture} activeOpacity={0.6}>
            <Text style={styles.button}>TAKE PICTURE</Text>
          </TouchableOpacity>
        )}
        {Platform.OS != "web" && (
          <TouchableOpacity onPress={pickImage} activeOpacity={0.6}>
            <Text style={styles.button}>PICK IMAGE</Text>
          </TouchableOpacity>
        )}
        {image && Platform.OS != "web" && (
          <TouchableOpacity
            style={styles.buttons}
            onPress={() => SaveToPhone(image)}
          >
            <Text style={styles.button}>SAVE</Text>
          </TouchableOpacity>
        )}
      </View>

      {Platform.OS == "web" && image && windowWidth < 480 && (
        <View style={styles.buttons_mobile_web}>
          <View style={styles.buttons_mobile_web_in}>
            <TouchableOpacity onPress={() => rotate90L()} activeOpacity={0.6}>
              <Text style={styles.button_mobile_web}>
                <Fontisto name="arrow-return-left" style={styles.edit} />
              </Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => rotate90R()} activeOpacity={0.6}>
              <Text style={styles.button_mobile_web}>
                <Fontisto name="arrow-return-right" style={styles.edit} />
              </Text>
            </TouchableOpacity>
            {image && (
              <TouchableOpacity
                onPress={() => pickImage(image)}
                activeOpacity={0.6}
              >
                <Text style={styles.button_mobile_web}>PICK</Text>
              </TouchableOpacity>
            )}
          </View>
          <View style={styles.buttons_mobile_web_in}>
            <TouchableOpacity
              onPress={() => flipHorizontal()}
              activeOpacity={0.6}
            >
              <Text style={styles.button_mobile_web}>
                <MaterialIcons name="flip" style={styles.edit} />
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => flipVertical()}
              activeOpacity={0.6}
            >
              <Text style={styles.button_mobile_web}>
                <MaterialCommunityIcons
                  name="flip-vertical"
                  style={styles.edit}
                />
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      )}

      {Platform.OS == "web" && image && windowWidth > 480 && (
        <View style={styles.buttons}>
          <TouchableOpacity onPress={() => rotate90L()} activeOpacity={0.6}>
            <Text style={styles.button}>
              <Fontisto name="arrow-return-left" style={styles.edit} />
            </Text>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => rotate90R()} activeOpacity={0.6}>
            <Text style={styles.button}>
              <Fontisto name="arrow-return-right" style={styles.edit} />
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => flipHorizontal()}
            activeOpacity={0.6}
          >
            <Text style={styles.button}>
              <MaterialIcons name="flip" style={styles.edit} />
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => flipVertical()} activeOpacity={0.6}>
            <Text style={styles.button}>
              <MaterialCommunityIcons
                name="flip-vertical"
                style={styles.edit}
              />
            </Text>
          </TouchableOpacity>
          {image && (
            <TouchableOpacity
              onPress={() => pickImage(image)}
              activeOpacity={0.6}
            >
              <Text style={styles.button}>PICK</Text>
            </TouchableOpacity>
          )}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-around",
    backgroundColor: "#121212",
  },
  imageContainer: {
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 5,
  },
  image: {
    width: 380,
    height: 500,
    resizeMode: "contain",
    marginTop: 5,
  },
  imageWeb: {
    width: 768,
    height: 525,
    resizeMode: "contain",
    marginTop: 5,
  },

  buttons: {
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#121212",
    marginTop: -2,
  },
  button: {
    backgroundColor: "#00afa3",
    width: 120,
    padding: 10,
    textAlign: "center",
    borderRadius: 10,
    height: 40,
    fontWeight: "bold",
    zIndex: 999,
    color: "#fff",
  },
  buttons_mobile_web: {
    justifyContent: "space-around",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#121212",
  },
  buttons_mobile_web_in: {
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#121212",
    margin: 5,
  },
  button_mobile_web: {
    backgroundColor: "#00afa3",
    width: 120,
    padding: 10,
    textAlign: "center",
    borderRadius: 10,
    height: 40,
    fontWeight: "bold",
    zIndex: 999,
    color: "#fff",
    margin: 5,
  },
  edit: { fontSize: 25 },
});

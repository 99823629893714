import React, { useState, useEffect } from "react";
import {
  Text,
  Image,
  StyleSheet,
  View,
  TouchableOpacity,
  Modal,
  Pressable,
  Platform,
} from "react-native";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import * as Linking from "expo-linking";

export default function Wallpaper() {
  const API_KEY = "563492ad6f917000010000018dbfe207efd14b3f9bebb9f16c015d0a";
  const API_URL =
    "https://api.pexels.com/v1/search?query=nature&orientation=portrait&size=small&per_page=100";

    const fetchImagesPexels = async () => {
      const data = await fetch(API_URL, { headers: { Authorization: API_KEY } });
      const { photos } = await data.json();
  
      return photos;
    };

  const [page, SetPage] = useState(0);

  const [modalVisible, setModalVisible] = useState(false);



  const [images, setImages] = useState(null);
  useEffect(() => {
    const fetchImages = async () => {
      const images = await fetchImagesPexels();
      setImages(images);
      /*console.log("----------");
      console.log(images[page]);
      console.log("----------");
      console.log("Title:", images[page].alt);
      console.log("Author:", images[page].photographer);
      console.log("Link:", images[page].url);
      console.log("Download:", images[page].src.original);*/
    };

    fetchImages();
  }, []);

  const NextImage = async () => {
    if (page == 79) {
      SetPage(0);
    } else {
      SetPage(page + 1);
    }
  };

  const RandomImage = async () => {
    const ran = Math.floor(Math.random() * 80);
    SetPage(ran);
  };

  const PrevImage = async () => {
    if (page == 0) {
      SetPage(79);
    } else {
      SetPage(page - 1);
    }
  };

  if (!images) {
    return (
      <View style={styles.loading}>
        <Text style={{ fontWeight: "bold", fontSize: 20, color:"#fff" }}>Loading...</Text>
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.infoContainer}
        onPress={() => setModalVisible(true)}
        activeOpacity={0.4}
      >
        <AntDesign name="infocirlceo" style={styles.info} />
      </TouchableOpacity>

      <View style={styles.imageContainer}>
        <Image
          source={{
            uri:
              Platform.OS != "web"
                ? images[page].src.large2x
                : images[page].src.landscape,
          }}
          style={Platform.OS != "web" ? styles.image : styles.imageWeb}
        />
      </View>

      <View style={Platform.OS != "web" ? styles.buttons : styles.buttonsWeb}>
        <TouchableOpacity onPress={() => PrevImage()} activeOpacity={0.6}>
          <Text style={styles.button}>PREV</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => RandomImage()} activeOpacity={0.6}>
          <Text style={styles.button}>RANDOM</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => NextImage()} activeOpacity={0.6}>
          <Text style={styles.button}>NEXT</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.centeredView}>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Text style={styles.modalText}>{images[page].alt}</Text>
              <Text style={styles.modalText}>{images[page].photographer}</Text>
              <Text
                style={styles.modalText}
                onPress={() => {
                  Linking.openURL(images[page].url);
                }}
              >
                {images[page].url}
              </Text>
              <TouchableOpacity
                style={[styles.button, styles.buttonClose]}
                onPress={() => setModalVisible(!modalVisible)}
              >
                <Text style={styles.textStyle}>CLOSE</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "#121212",
    alignItems: "center",
  },
  imageContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 768,
    height: 1024,
    resizeMode: "stretch",
  },
  imageWeb: {
    width: 922,
    height: 630,
    resizeMode: "contain",

  },
  buttons: {
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    bottom: 10,
    width: "100%",
    zIndex: 99,
  },
  buttonsWeb: {
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    bottom: 50,
    width: "500px",
    zIndex: 99,
    
  },
  button: {
    backgroundColor: "#00afa3",
    padding: 10,
    textAlign: "center",
    borderRadius: 10,
    width: 100,
    height: 40,
    opacity: 0.8,
    fontWeight: "bold",
    fontSize: 16,
    color:"#fff"
  },
  loading: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#121212",
    

  },
  infoContainer: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 99,
  },
  info: { color: "rgba(0,0,255, 0.7)", fontSize: 40 },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "#121212",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  buttonClose: {
    backgroundColor: "#00afa3",
    padding: 10,
    textAlign: "center",
    borderRadius: 10,
    width: 90,
    height: 40,
    opacity: 0.7,
    fontWeight: "bold",
    fontSize: 16,
    color:"#fff"
  },
  textStyle: {
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
    color: "#fff",
  },
});

import { StyleSheet, Text, View } from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";

export default function Options() {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Text>
          <Entypo name="info" size={100} color="white" />
        </Text>
      </View>
      <View style={styles.footerContainer}>
        <Text style={styles.footer}>
          <FontAwesome5 name="copyright" style={styles.info} /> Dariusz
          Kowalczyk 2022
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#121212",
  },
  content: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#121212",
  },
  info: {  fontSize: 18, fontWeight: "bold" ,color:"#fff"},
  footer: { fontSize: 20, padding: 10, fontWeight: "bold" ,color:"#fff"},
  footerContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-end",
    flexDirection: "row",
  },
});

import React, { useState } from "react";
import { Text, Image, StyleSheet, View, TouchableOpacity } from "react-native";
import * as MediaLibrary from "expo-media-library";

export default function Gallery() {
  const [localUri, setlocalUri] = useState(null);
  const [page, SetPage] = useState(0);
  const [totalCount, settotalCount] = useState(0);

  const pickAlbum = async () => {
    await MediaLibrary.requestPermissionsAsync();
    const getAllPhotos = await MediaLibrary.getAssetsAsync({
      first: 9999,
      sortBy: ["modificationTime"],
    });

    setlocalUri(getAllPhotos.assets[page].uri);
    settotalCount(getAllPhotos.totalCount);
  };
  try {
    pickAlbum();
  } catch (error) {
    console.log(error);
  }

  const NextImage = async () => {
    if (page == totalCount - 1) {
      SetPage(0);
    } else {
      SetPage(page + 1);
    }
  };

  const PrevImage = async () => {
    if (page == 0) {
      SetPage(totalCount - 1);
    } else {
      SetPage(page - 1);
    }
  };

  if (!localUri) {
    return (
      <View style={styles.loading}>
        <Text style={{ fontWeight: "bold", fontSize: 20, color:"#fff" }}>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        {
          <Image
            source={{ uri: localUri, isStatic: true }}
            style={styles.image}
          />
        }
      </View>
      <View style={styles.buttons}>
        <TouchableOpacity onPress={() => PrevImage()} activeOpacity={0.6}>
          <Text style={styles.button}>PREV</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => NextImage()} activeOpacity={0.6}>
          <Text style={styles.button}>NEXT</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "#121212",
  },
  imageContainer: {
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 5,
  },
  image: {
    width: 380,
    height: 500,
    resizeMode: "contain",
    marginBottom: 20,
  },

  buttons: {
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center",
   
  },
  button: {
    backgroundColor:"#00afa3",
    width: 80,
    padding: 10,
    textAlign: "center",
    borderRadius: 10,
    height: 40,
    fontWeight: "bold",
    color:"#fff"
  },
  loading: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#121212",
  },
});

import {
  StyleSheet,
  Text,
  View,
  Image,
  Platform,
  TouchableOpacity,
  ImageBackground,
} from "react-native";
import { AntDesign, FontAwesome } from "@expo/vector-icons";

export default function Home({ navigation }) {
  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../assets/pexels-aron-visuals-1743165.jpg")}
        style={styles.image}
      >
        <View style={styles.logoContainer}>
          <Image
            style={styles.logo}
            source={require("../assets/logo_transparent.png")}
          />
        </View>
        <View style={styles.menu}>
          <TouchableOpacity
            onPress={() => navigation.navigate("Editor")}
            activeOpacity={0.6}
          >
            <Text style={styles.button}>IMAGE EDITOR</Text>
          </TouchableOpacity>

          {Platform.OS != "web" && (
            <TouchableOpacity
              onPress={() => navigation.navigate("Gallery")}
              activeOpacity={0.6}
            >
              <Text style={styles.button}>GALLERY</Text>
            </TouchableOpacity>
          )}
          <TouchableOpacity
            onPress={() => navigation.navigate("Wallpaper")}
            activeOpacity={0.6}
          >
            <Text style={styles.button}>WALLPAPER</Text>
          </TouchableOpacity>
        </View>
      </ImageBackground>
      <TouchableOpacity
        style={styles.infoContainer}
        onPress={() => navigation.navigate("Options")}
        activeOpacity={0.4}
      >
        <FontAwesome name="gear" style={styles.info} />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor:"#000"
  },
  logo: {
    width: 300,
    height: 300,
  },
  logoContainer: {
    height: "60%",
  },
  button: {
    padding: 10,
    borderRadius: 20,
    backgroundColor: "#00afa3",
    width: 250,
    textAlign: "center",
    marginBottom: 30,
    color: "#fff",
    shadowColor: "lightblue",
    shadowOpacity: 0.6,
    shadowRadius: 5,
    elevation: 10,
    fontWeight: "bold",
  },
  menu: { marginTop: 100 },
  image: {
    width: "100%",
    height: "100%",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-around",
  },
  infoContainer: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 99,
  },
  info: { color: "rgba(220,220,220, 0.7)", fontSize: 40 },
});
